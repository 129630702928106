<template>
	<div class="signup-container px-4 py-8 md:px-6 lg:px-8 flex flex-column align-items-center justify-content-start">
		<img class="mb-5" src="../../assets/logo-white.svg" alt="">
		<div class="text-center mb-5">
			<div class="font-36 mb-3 p-text-primary">Sign Up</div>
		</div>

		<div class="surface-card p-4 shadow-2 border-round w-full lg:w-8">
			<div class="card">
				<div class="flex flex-column">
					<PrimeSteps ref="stepsUI" :model="items" :readonly="false">
						<template #item="{ item }">
							<router-link :to="item.to" custom>
								<ul class="p-steps-item cursor-pointer" role="tab" aria-selected="false"
									aria-expanded="false">
									<a @click="onClickStepItem(item)" class="p-menuitem-link" role="presentation">
										<span class="p-steps-number"
											:class="{ 'background-selected': item.index <= currentIndex }">
											{{ item.index + 1 }}</span>
										<span class="p-steps-title">{{ item.label }}</span>
									</a>
								</ul>
							</router-link>
						</template>
					</PrimeSteps>
					<div class="line-seperate mb-6"></div>
					<router-view v-slot="{ Component, route }" :formData="formObject" @prevPage="prevPage($event)"
						@nextPage="nextPage($event)" @complete="complete">
						<keep-alive>
							<component :is="Component" :key="route.name"></component>
						</keep-alive>
					</router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onBeforeMount, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { signup } from '@/services/auth.service';
import { useBlockUI } from '@/composables/useBlockUI';
import { useToast } from 'primevue/usetoast';
import { RESPONSE_CODE } from '@/common/const/modules/index';
import { useValidateCrossSite } from '@/composables/useValidateCrossSite';
import { generateReturnUrl, handleErrorApi } from '@/common/helpers/utils';

export default {
	name: 'SignUp',
	setup() {
		const router = useRouter();
		const stepsUI = ref(null);
		const currentIndex = ref(0);
		const { companyNameExistedCb, setCompanyNameExistedCb } = useValidateCrossSite();
		const { unblockDocument, blockDocument } = useBlockUI();
		const toast = useToast();
		const items = ref([
			{
				index: 0,
				label: 'User Info',
				to: '/sign-up'
			},
			{
				index: 1,
				label: 'Company Info',
				to: '/sign-up/company-info'
			},
			{
				index: 2,
				label: 'Payment Info',
				to: '/sign-up/payment-info'
			},
			{
				index: 3,
				label: 'Terms & Conditions',
				to: '/sign-up/term-and-conditions'
			}
		]);
		const formObject = ref({});

		onBeforeMount(() => {
			validateStep();
		});

		onUnmounted(() => {
			setCompanyNameExistedCb(null);
		});

		const validateStep = () => {
			const currentRoute = items.value.find(item => item.index === currentIndex.value);
			if (router.currentRoute.value.fullPath.includes(currentRoute.to)) {
				router.push(items.value[0].to);
			}
		};

		const nextPage = (event = { pageIndex: 0, formData: {} }) => {
			if (event.pageIndex < items.value.length) {
				currentIndex.value = event.pageIndex + 1;
				for (const field in event.formData) {
					formObject.value[field] = event.formData[field];
				}
				router.push(items.value[event.pageIndex + 1].to);
			}
		};
		const prevPage = (event = { pageIndex: 0 }) => {
			if (event.pageIndex > 0) {
				currentIndex.value = event.pageIndex - 1;
				router.push(items.value[event.pageIndex - 1].to);
			}
		};
		const handleServerErr = (err) => {
			let message = '';
			switch (err?.status) {
				case RESPONSE_CODE.BAD_REQUEST:
					if (err.data?.errorMessage) {
						message = err.data.errorMessage;
						if (message.includes('Company Name is duplicated.')) {
							message = 'The name already exists';
							if (companyNameExistedCb.value !== null) {
								companyNameExistedCb.value(message);
							}
						}
					} else {
						// show error
						message = handleErrorApi(err);
					}
					break;
				case RESPONSE_CODE.INTERNAL_SERVER_ERROR:
				default:
					message = handleErrorApi(err);
					break;
			}
			unblockDocument();
			toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
		};

		const complete = async (value) => {
			if (value.formData.termAndConditions) {
				blockDocument();
				// break ref object to basic object data
				const dataRequest = { ...formObject.value };
				// assign return url to data request
				dataRequest.successReturnUrl = generateReturnUrl('/verify-success');
				dataRequest.failedReturnUrl = generateReturnUrl(`/verify-error?email=${encodeURIComponent(dataRequest.email)}`);
				try {
					const res = await signup(dataRequest);
					if (res && res.status === RESPONSE_CODE.SUCCESS) {
						router.push(`/verification?email=${encodeURIComponent(dataRequest.email)}`);
					} else {
						handleServerErr(res);
					}
					unblockDocument();
				} catch (ex) {
					unblockDocument();
					toast.add({ severity: 'error', summary: 'Error', detail: handleErrorApi(ex.response), life: 3000 });
				}
			}
		};

		const onClickStepItem = (item) => {
			if (item.index > currentIndex.value) {
				return;
			}
			if (!stepsUI.value.isActive(item)) {
				currentIndex.value = item.index;
				router.push(items.value[item.index].to);
			}
		};

		return { items, formObject, stepsUI, currentIndex, nextPage, prevPage, complete, onClickStepItem };
	}
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
