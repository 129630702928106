<template>
	<div class="verification-sent-container px-4 py-8 flex flex-column align-items-center justify-content-start">
		<div
			class="surface-card py-8 shadow-2 border-round w-full lg:w-6 flex flex-column align-items-center justify-content-center">
			<div class="text-center font-bold">
				<div class="text-3xl mb-3">Congratulations, your account has been verified.</div>
			</div>
			<router-link to="/login">
				<span class='link text-xl'>Click here to login</span>
			</router-link>
		</div>
	</div>
</template>

<script>

export default {
	name: 'VerificationSuccess',
	setup() {
		return {};
	}
};
</script>

<style lang='scss' scoped>
@import './style.scss';
</style>
