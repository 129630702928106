<template>
	<div class="block-content">
		<div class="main-layout-container">
			<div class="min-h-screen flex relative lg:static surface-ground">
				<div class="min-h-screen flex flex-column relative flex-auto">
					<div
						class="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static header-container">
						<div class="flex">
							<div class="flex align-items-center flex-shrink-0 logo-wrapper">
								<img src="../assets/logo.svg" alt="Image" height="20" />
							</div>
						</div>
						<div class="flex justify-content-center text-xl ">
							<router-link class="m-5 text-0" to="/">Dashboard</router-link>
							<router-link v-if="isClientRead" class="m-5 text-0" to="/client-management">Client
								Management</router-link>
							<router-link v-if="isCampaignRead" class="m-5 text-0" to="/campaign-management">Campaign
								Management</router-link>
						</div>
						<div class="flex justify-content-end  align-items-center">
							<BellToolBarVue></BellToolBarVue>
							<ul class="list-none p-0 m-0">
								<div class="user-name">
									<li class="border-top-1 surface-border lg:border-top-none">
										<UserToolBar></UserToolBar>
									</li>
								</div>
							</ul>
						</div>
					</div>
					<div class="flex flex-column flex-auto overflow-y-auto main-container-bg">
						<div class="flex-auto">
							<PrimeScrollPanel class="custombar">
								<router-view></router-view>
							</PrimeScrollPanel>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!---->
	</div>
</template>

<script>
import { ref, computed, reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useProfile } from '@/composables/userProfile';
import { getProfile } from '@/services/user.service';
import { useToast } from 'primevue/usetoast';
import { useBlockUI } from '@/composables/useBlockUI';
import { RESPONSE_CODE } from '@/common/const/modules/index';
import UserToolBar from '@/components/UserToolBar/UserToolBar.vue';
import BellToolBarVue from '@/components/UserToolBar/BellToolBar.vue';
import menuItems from './menuItems';
import { usePermissions } from '@/composables/useRoles';

export default {
	name: 'MainLayout',
	components: {
		UserToolBar,
		BellToolBarVue
	},
	setup() {
		const { setProfile, profile } = useProfile();
		const { unblockDocument, blockDocument } = useBlockUI();
		const toast = useToast();
		const route = useRoute();
		const name = computed(() => route?.name);
		const menuList = ref(menuItems || []);
		const { isCampaignRead, isClientRead } = usePermissions();
		const state = reactive({
			home: {
				icon: 'pi pi-home',
				to: '/'
			},
			items: [{ label: name }],
			menuList
		});

		onMounted(async () => {
			// get profile data;
			blockDocument();
			const { data, status, statusText } = await getProfile();
			if (data && status === RESPONSE_CODE.SUCCESS) {
				setProfile(data);
				unblockDocument();
			} else {
				unblockDocument();
				toast.add({ severity: 'error', summary: 'Error', detail: statusText, life: 3000 });
			}
		});

		return {
			state,
			profile,
			setProfile,
			isCampaignRead,
			isClientRead
		};
	}

};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/theme-variable.scss';

.main-layout-container {
	height: 100vh;
	overflow: hidden;

	.header-container {
		height: 60px;
		background-color: #1F1F1F !important;
	}
}

.logo-wrapper {
	height: 60px;
}

:deep(.p-scrollpanel) {
	p {
		padding: .5rem;
		line-height: 1.5;
		margin: 0;
	}

	.main-container-bg {
		background-color: $p-theme-background;
	}

	&.custombar {
		height: calc(100vh - 60px); // 60 is height of top bar

		.p-scrollpanel-bar {
			background-color: transparent;
			opacity: 1;
			transition: background-color .2s;

			&:hover {
				background-color: transparent;
			}
		}
	}
}

.router-link-exact-active {
	color: $p-primary-color !important;
}
</style>
