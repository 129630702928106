import { axios as api } from '../common/helpers/api';
import { API_URL, SERVICES, API_VERSION } from '../common/configs/api';
export function getNotifications(params) {
	return api.get(`${SERVICES.CORE}${API_VERSION}${API_URL.NOTIFICATIONS}`, { params });
};
export function getAmountNotifications() {
	return api.get(`${SERVICES.CORE}${API_VERSION}${API_URL.NOTIFICATIONS}/${API_URL.UNREAD}`);
}
export function updateRead(id, isRead) {
	return api.put(`${SERVICES.CORE}${API_VERSION}${API_URL.NOTIFICATIONS}/${id}/${API_URL.STATUS}/${isRead}`);
};
export function updateReadAll() {
	return api.put(`${SERVICES.CORE}${API_VERSION}${API_URL.NOTIFICATIONS}/${API_URL.READ_ALL}`);
};
