export const BASE_API_URL = process.env.VUE_APP_BASE_API;
export const STRIPE_API_KEY = process.env.VUE_APP_STRIPE_API_KEY;
export const API_VERSION = '/api/v1/';

export const SERVICES = {
	IDENTITY: 'gateway/identity',
	CORE: 'gateway/core',
	PAYMENT: 'gateway/payment',
	STORAGE: 'gateway/storage'
};

export const API_URL = {
	// Guest constant
	SIGN_IN: 'Auth/token',
	REGISTER: 'signup',
	SIGN_OUT: 'Auth/logout',
	USER_EXISTS: 'Users/email-availability',
	TENANTS: 'Tenants',
	RESEND_EMAIL_CONFIRMATION: '/resend-email-confirmation',

	// Geolocation
	GEOS_COUNTRY: 'Geos/countries',
	GEOS_CITY: 'Geos/cities',
	GEOS_STATE: 'Geos/states',

	// clients constant
	CLIENT: 'Clients',
	CLIENT_OPTIONS: 'options',
	// sparks
	SPARK: 'sparks-setting',
	NEARBY_LOCATIONS: 'nearby-locations',
	HISTORY: 'Histories',
	// ads constant
	AD: 'ads',
	LOCATION: 'ad-locations',
	TIME: 'ad-times',
	AGENCIES: 'agencies',

	// Current user constant
	PROFILE: 'Users/profile',
	AVATAR: 'avatar',
	USERS: 'Tenants/-/users',
	CHANGE_PASSWORD: 'Users/-/password',
	FORGOT_PASSWORD: 'Users/forgot-password',
	NEW_PASSWORD: 'Users/passworded',
	// common constant
	DISTRICT: 'districts',
	TIME_ZONES: 'timezones',
	COUNTRY: 'countries',
	STATE: 'states',
	CITY: 'cities',
	DEVICE: 'devices',
	STATUS: 'status',

	// Calculator
	CALCULATORS: 'Calculators',
	SPARKS: 'sparks',
	CAMPAIGNS: 'Campaigns',
	UPLOAD_MEDIA: 'upload-media',
	REPORTS: 'Reports',
	REPORT: 'Report',
	CLIENT_CAMPAIGN: 'client-campaigns',
	FACE_DETECTIONS: 'face-detections',
	CAMPAIGN_STATUS: 'status',
	CLIENT_CAMPAIGN_DISTRIBUTION: 'dashboard',

	// setSpark payment
	SET_COST_PAYMENT: 'settings',
	TRANSACTION_PAY: 'payments',
	CONVERT_SPARK: 'calculator',

	// Notifications
	NOTIFICATIONS: 'Notifications',
	UNREAD: 'unread/count',
	READ_ALL: 'read-all',

	// Face Recognition Visualize
	TOTAL_DETECTIONS: 'detections/faces/total',
	STREAMS: 'Files/streams',
	DETAIL_DETECTIONS: 'detections/faces/total-time'
};

export const REQUEST_ACTION = {
	ADD: 'add',
	EDIT: 'edit',
	UPDATE: 'update',
	DELETE: 'delete',
	REMOVE: 'remove'
};

export const REQUEST_TYPE = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DEL: 'DELETE',
	PATCH: 'PATCH'
};
