import { AES, enc } from 'crypto-js';

const get = (key = '') => {
	const value = localStorage.getItem(key);
	if (value) {
		return AES.decrypt(value, key).toString(enc.Utf8);
	};
	return '';
};

const set = (key = '', value = '') => {
	if (value) {
		return localStorage.setItem(key, AES.encrypt(value, key).toString());
	};
	return '';
};

const remove = (key = '') => {
	return localStorage.removeItem(key);
};

const clear = () => {
	return localStorage.clear();
};

const setNormal = (key = '', value = '') => {
	if (value) {
		return localStorage.setItem(key, JSON.stringify(value));
	};
	return '';
};

const getNormal = (key = '') => {
	const value = localStorage.getItem(key);
	if (value) {
		return JSON.parse(value);
	}
	return value;
};

export default {
	get,
	set,
	remove,
	clear,
	setNormal,
	getNormal
};
