import { reactive, toRefs } from 'vue';

const errors = reactive({
	companyNameExistedCb: null,
	campaignNameExistCb: null
});

export const useValidateCrossSite = () => {
	const setCompanyNameExistedCb = (cb) => {
		errors.companyNameExistedCb = cb;
	};

	const setCampaignNameExistedCb = (cb) => {
		errors.campaignNameExistCb = cb;
	};

	return {
		setCompanyNameExistedCb,
		setCampaignNameExistedCb,
		...toRefs(errors)
	};
};
