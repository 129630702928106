// App constants
export const APP_NAME = 'LightX';
export const SET_PAGE_TITLE = 'setPageTitle';
export const SET_BREADCRUMB = 'setBreadcrumb';
export const SET_TITLE = 'setTitle';
export const ENUM_STATUS = {
	ACTIVE: 'active',
	INACTIVE: 'inactive'
};
// Message API error
export const CONTACT_ADMINISTRATION = 'Somethings went wrong. Please contact administration';
export const NOT_PERMISSION = 'You don’t have permission to perform this action';
// Containers constant
export const DASHBOARD = 'Dashboard';
export const TERMS_CONDITIONS = 'Terms & Conditions';
export const PROFILE = 'My Profile';
export const CLIENTS_EDITION = 'Edit client';
export const CLIENTS_MANAGEMENT = 'Clients management';
export const CLIENTS_CREATION = 'Add client';
export const ADS_MANAGEMENT = 'Ads Management';
export const ADS_CREATION = 'Add Ad';
export const ADS_EDITION = 'Edit Ad';
export const USERS_MANAGEMENT = 'Users management';
export const USERS_CREATION = 'Add user';
export const USERS_EDITION = 'Edit user';
export const NOT_FOUND = 'Not found';
export const CLIENT_HEADER = {
	CREATE: 'Create Client',
	EDIT: 'Edit Client Details',
	LIST: 'Client List'
};
export const USER_HEADER = {
	LIST: 'User List',
	CREATE: 'Create User',
	EDIT: 'Edit User Details'
};
export const CAMPAIGN_HEADER = {
	LIST: 'Campaign List'
};

export const STATUS_CAMPAIGN = {
	COMPLETED: 'Completed',
	ACTIVE: 'Active',
	INACTIVE: 'Inactive'
};

export const AUTH_KEY = 'token';
export const TOKEN_TYPE = 'tokenType';
export const USER_KEY = 'user';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const LIST_CAMPAIGN_DUE_DATE_SHOWED = 'listCampaignDueDateShowed';
export const REFUND = 'refund';
export const SUCCESS = 'success';

export const MAX_VIDEO_DURATION = 10;
export const MAX_LENGTH_INPUT_TEXT = 60;
export const MAX_LENGTH_INPUT_PASSWORD = 12;
export const MAX_LENGTH_INPUT_PHONE_NUMBER = 12;
export const MAX_LENGTH_TEXT_AREA = 255;

export const TIME_OUT = 3000;
export const TIME_OUT_DELAY = 4000;

export const REGEX_VALIDATION = {
	PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^*\-_+={}[\]/;:,.?])[A-Za-z0-9~!@#$%^*\-_+={}[\]/;:,.?]{6,}$/
};

export const NUMBER_PAGE_SIZE_NOTIFICATION = 20;
export const ACTION_TYPE = {
	COMPLETE_CAMPAIGN: 'CompleteCampaign',
	PAYMENT: 'PaymentSuccess',
	CREATE_CAMPAIGN: 'CreateCampaign',
	TRACK_DUE_DATE: 'CampaignDueDate'
};
export const MAX_FILE_VIDEO_CAMPAIGN = 31457280; // 100 * 1024 * 1024 = 30MB
export const MAX_FILE_IMAGE_CAMPAIGN = 5242880; // 5 * 1024 * 1024 = 5MB

export const RESPONSE_CODE = {
	SUCCESS: 200,
	NO_CONTENT: 204,
	MULTIPLE_CHOICE: 300,
	UNAUTHORIZED: 401,
	NOT_FOUND: 404,
	NOT_PERMISSION: 403,
	UNPROCESSABLE_ENTITY: 422,
	BAD_REQUEST: 400,
	INTERNAL_SERVER_ERROR: 500
};

export const DATE_FORMAT_SHORT_MONTH = 'MMM DD, YYYY';
export const FULL_NAME_MONTH = 'MMMM';
export const CAMPAIGN_LIST_DATE_FORMAT = 'MMMM Do, YYYY';
export const CAMPAIGN_LIST_HOUR_FORMAT = 'hh:mm A';
export const CAMPAIGN_LIST_CHECK_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm:ss A';
export const DATE_FORMAT_CALENDAR = 'DD MMM YYYY';
export const DATE_FORMAT_PARAMS = 'YYYY-MM-DD';

export const ROLES = {
	USER: 'User',
	ADMINISTRATOR: 'Administrator',
	SUPER_ADMINISTRATOR: 'Super Administrator'
};

export const CAMPAIGN_FORM_MODE = {
	CREATE: 'create',
	EDIT: 'edit',
	VIEW: 'view',
	COPY: 'copy'
};
// Chart config
export const DEFAULT_MAX_VALUE_YAXIS = 10;
export const DEFAULT_PERCENT_BUFFER_YAXIS = 0.1; // that is 10%
export const PADDING_LAYOUT = 12;
export const COLOR_CHART_CAMPAIGN = {
	BAR_FILL: [
		{
			POINT: 0,
			COLOR: '#EFB058'
		},
		{
			POINT: 0.25,
			COLOR: '#EFB058'
		},
		{
			POINT: 0.5,
			COLOR: '#EFB058'
		},
		{
			POINT: 1,
			COLOR: '#F5953B'
		}
	],
	BAR_BACKGROUND: [
		{
			POINT: 0,
			COLOR: '#ffffff'
		},
		{
			POINT: 1,
			COLOR: '#EBEBEB'
		}
	]
};
export const COLOR_CHART_FACE_RECOGNITION = {
	BAR_FILL: [
		{
			POINT: 0,
			COLOR: '#F5953B'
		},
		{
			POINT: 1,
			COLOR: '#F5953B'
		}
	],
	BAR_BACKGROUND: [
		{
			POINT: 0,
			COLOR: '#FFFFFF'

		},
		{
			POINT: 1,
			COLOR: '#EBEBEB'
		}
	],
	FEMALE: '#97CCBC',
	MALE: '#A3ADFF'

};
export const DASH_COLOR = '#F0C0C0';
export const AXES_COLOR = '#EBE0FB';
export const AGE_RANGE = [
	{
		name: 'All',
		value: {
			name: 'All'
		}
	},
	{
		name: 'Under 18',
		value: {
			max: 17,
			name: 'Under 18'
		}
	},
	{
		name: '18-24',
		value: {
			min: 18,
			max: 24,
			name: '18-24'
		}
	},
	{
		name: '25-34',
		value: {
			min: 25,
			max: 34,
			name: '25-34'
		}
	},
	{
		name: '35-44',
		value: {
			min: 35,
			max: 44,
			name: '35-44'
		}
	},
	{
		name: '45-54',
		value: {
			min: 45,
			max: 54,
			name: '45-54'
		}
	},
	{
		name: '55-64',
		value: {
			min: 55,
			max: 64,
			name: '55-64'
		}
	},
	{
		name: 'Over 64',
		value: {
			min: 65,
			name: 'Over 64'
		}
	}

];

export const FREQUENCY_LIST = [{
	name: 'Month',
	value: 0
}, {
	name: 'Year',
	value: 1
}];
export const LIST_MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const BAR_BACKGROUND_CAMPAING_PLUGIN = 'barBackgroundCampaign';
export const BAR_BACKGROUND_FACE_PLUGIN = 'barBackgroundFace';

export const DROPDOWN_LABEL_CHART = {
	FREQUENCY: 'frequency',
	MONTH: 'month',
	YEAR: 'year',
	CAMPAIGN: 'campaign'
};
