import { axios as api } from '../common/helpers/api';
import { API_URL, API_VERSION, SERVICES } from '../common/configs/api';

export const getProfile = () => api.get(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.PROFILE}`);

export function getMe() {
	return api.get(API_URL.PROFILE);
}

export function updateProfile(formData = {}) {
	return api.put(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.PROFILE}`, formData, { useFormData: true });
}
export function updateAvatar(avatar = {}) {
	return api.put(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.PROFILE}/${API_URL.AVATAR}`, avatar, { useFormData: true });
}

export function getAvatar() {
	return api.get(API_URL.AVATAR);
}
export function createUser(User = {}) {
	return api.post(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.USERS}`, User);
}
export function loadUsers(params) {
	return api.get(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.USERS}`, { params: params });
}
export function updateUser(id, user) {
	return api.put(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.USERS}/${id}`, user);
}
export function loadUser(id) {
	return api.get(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.USERS}/${id}`);
}
export function deleteUser(userId) {
	return api.delete(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.USERS}/${userId}`);
}
export function changePassword(password) {
	return api.put(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.CHANGE_PASSWORD}`, password);
}
export function forgotPassword(params) {
	return api.post(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.FORGOT_PASSWORD}`, params);
}
export function changeNewPassword(params) {
	return api.post(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.NEW_PASSWORD}`, params);
}
export function getHistoryTracking(params) {
	return api.get(`${SERVICES.CORE}${API_VERSION}${API_URL.SPARKS}/${API_URL.HISTORY}`, { params: params });
}
export function getExportCsv(params) {
	return api.get(`${SERVICES.CORE}${API_VERSION}${API_URL.SPARKS}/${API_URL.REPORT}`, { params: params }, { responseType: 'blob' });
}
