export default [
	{
		isHeader: true,
		name: 'MAIN NAVIGATION',
		icon: 'pi pi-chevron-down ml-auto'
	},
	{
		icon: 'pi pi-home mr-2',
		name: 'Dashboard',
		router: {
			path: '/'
		}
	}
];
