export const globalMixins = {
	methods: {
		uid(id) {
			return `${this._uid}_${id}`;
		},

		noop() {
			// noop for stopping propagation
		},
		hasErrors(prop, scope = '') {
			return this.errors.has(`${scope ? scope + '.' : ''}${prop}`);
		},
		showError(field, classes = null) {
			return this.errors.has(field) ? classes || 'has-error' : '';
		}
	}
};
