import Storage from '@/common/helpers/storage/localStorage';
import { AUTH_KEY, TOKEN_TYPE } from '../const/modules';

export const authHeader = () => {
	// return authorization header with jwt token
	const token = Storage.get(AUTH_KEY);

	if (token) {
		return {
			Authorization: `${Storage.get(TOKEN_TYPE)} ${token}`
		};
	}

	return {};
};
