<template>
	<div class="verification-error-container px-4 py-8 flex flex-column align-items-center justify-content-start">
		<div
			class="surface-card py-8 shadow-2 border-round w-full lg:w-6 flex flex-column align-items-center justify-content-center">
			<div class="text-center font-bold">
				<div class="text-xl mb-4">Your verification link has been expired. Click here to resend email</div>
			</div>
			<PrimeButton :disabled="!email" class="p-ripple btn-primary btn-rounded btn-theme-error" type="submit"
				label="Resend Email" @click="onClickResend()">
				<span>Resend Email</span>
			</PrimeButton>
		</div>
	</div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import { resendEmailVerification } from '@/services/auth.service';
import { generateReturnUrl, handleErrorApi } from '@/common/helpers/utils';
import { useToast } from 'primevue/usetoast';
import { useBlockUI } from '@/composables/useBlockUI';
import { RESPONSE_CODE } from '@/common/const/modules';

export default {
	name: 'VerificationError',
	setup() {
		const route = useRoute();
		const router = useRouter();
		const toast = useToast();
		const { unblockDocument, blockDocument } = useBlockUI();
		const email = route.query.email ? decodeURIComponent(route.query.email) : undefined;
		const handleServerErr = (err) => {
			let message = '';
			if (err.data?.errors) {
				const errors = err.data?.errors;
				const errorsMessage = errors[Object.keys(errors)[0]];
				if (errorsMessage.length) {
					message = errors[Object.keys(errors)[0]][0];
				} else {
					message = handleErrorApi(err);
				}
			} else if (err.data?.errorMessage) {
				message = err.data.errorMessage;
			} else {
				// show error
				message = handleErrorApi(err);
			}
			unblockDocument();
			toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
		};
		const onClickResend = async () => {
			blockDocument();
			try {
				const res = await resendEmailVerification({
					email: email,
					successReturnUrl: generateReturnUrl('/verify-success'),
					failedReturnUrl: generateReturnUrl(`/verify-error?email=${encodeURIComponent(email)}`)
				});
				if (res && res.status === RESPONSE_CODE.SUCCESS) {
					router.push('/login');
					unblockDocument();
				} else {
					handleServerErr(res);
				}
			} catch (ex) {
				unblockDocument();
				toast.add({ severity: 'error', summary: 'Error', detail: 'Internal Server Error', life: 3000 });
			}
		};
		return {
			email,
			onClickResend
		};
	}
};
</script>

<style lang='scss' scoped>
@import './style.scss';
</style>
