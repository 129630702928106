import { axios as api } from '../common/helpers/api';
import { API_URL, API_VERSION, SERVICES } from '../common/configs/api';

export function signup(user = {}) {
	return api.post(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.TENANTS}`, user);
}

export function signin(login = {}) {
	return api.post(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.SIGN_IN}`, login);
}

export function signout(refreshTokenParam) {
	return api.post(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.SIGN_OUT}/${refreshTokenParam}`);
}

export function refreshToken(refreshTokenParam) {
	return api.get(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.SIGN_IN}/${refreshTokenParam}`);
}

export function resendEmailVerification(params) {
	return api.get(`${SERVICES.IDENTITY}${API_VERSION}${API_URL.TENANTS}${API_URL.RESEND_EMAIL_CONFIRMATION}`, { params });
}
