import { RESPONSE_CODE } from '@/common/const/modules/index';
import { useAuth } from '@/composables/useAuth';

export const ErrorIntercept = (response) => {
	response.use(
		data => {
			return data;
		},
		err => {
			const res = err?.response || err?.message || err;
			if (res?.status === RESPONSE_CODE.UNAUTHORIZED) {
				const { logout } = useAuth();
				logout();
				window.location.reload();
			}
			return res;
		}
	);
};

export function handleApiError(error) {
	const res = error?.response;

	if (res === 'Network Error') {
		res.error = `There were some errors with your request: ${res}`;
	}

	if (res?.status === RESPONSE_CODE.UNPROCESSABLE_ENTITY && res?.data) {
		const errors = [];

		Object.keys(res?.data?.errors).forEach((field) => {
			res?.data?.errors[field].forEach((err) => {
				errors?.push(err);
			});
		});

		res.error = `There were some errors with your request: ${errors.join(', ')}`;
	}

	if (res?.status === RESPONSE_CODE.UNAUTHORIZED) {
		res.error = 'Your session has timed out. Please log in again.';
	}

	if (res?.status === RESPONSE_CODE.BAD_REQUEST) {
		res.error = res?.data?.error;
	}

	return Promise.reject(error);
}
