import { createRouter, createWebHistory } from 'vue-router';
import { AUTH_KEY } from '@/common/const/modules/index';
import MainLayout from '../layout/MainLayout.vue';
import SignUp from '../pages/SignUp/SignUp.vue';
import VerificationSent from '../pages/Verification/VerificationSent/VerificationSent.vue';
import VerificationSuccess from '../pages/Verification/VerificationSuccess/VerificationSuccess.vue';
import VerificationError from '../pages/Verification/VerificationError/VerificationError.vue';
import Terms from '../pages/Terms/Terms.vue';
import Storage from '@/common/helpers/storage/localStorage';

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import('../pages/Login/Login.vue')
	},
	{
		path: '/campaign-management/campaign-information/:id/visualization',
		name: 'FaceRecognitionVisualize',
		meta: {
			requiredAuth: true
		},
		component: () => import('../pages/CampaignManage/CampaignDetail/components/FaceRecognitionVisualize.vue')
	},
	{
		path: '/sign-up',
		name: 'SignUp',
		component: SignUp,
		children: [
			{
				path: '/sign-up',
				name: 'UserInfo',
				component: () => import('../pages/SignUp/Steps/UserInfo/UserInfo.vue')
			},
			{
				path: '/sign-up/company-info',
				name: 'CompanyInfo',
				component: () => import('../pages/SignUp/Steps/CompanyInfo/CompanyInfo.vue')
			},
			{
				path: '/sign-up/payment-info',
				name: 'PaymentInfo',
				component: () => import('../pages/SignUp/Steps/PaymentInfo/PaymentInfo.vue')
			},
			{
				path: '/sign-up/term-and-conditions',
				name: 'TermAndConditions',
				component: () => import('../pages/SignUp/Steps/TermAndConditions/TermAndConditions.vue')
			}
		]
	},
	{
		path: '/',
		name: '',
		component: MainLayout,
		meta: {
			requiredAuth: true
		},
		children: [
			{
				path: '/',
				name: 'dashboard',
				component: () => import('../pages/Dashboard/Dashboard.vue')
			},
			{
				path: '/client-management',
				name: 'clientManagement',
				// route level code-splitting
				// this generates a separate chunk (about.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "about" */ '../pages/Clients/ClientList.vue')
			},
			{
				path: '/client-management/create-client',
				name: 'createClient',
				component: () => import(/* webpackChunkName: "about" */ '../pages/Clients/CreateClient/CreateClient.vue')
			},
			{
				path: '/client-management/edit-client/:id',
				name: 'editClient',
				component: () => import(/* webpackChunkName: "about" */ '../pages/Clients/EditClient/EditClient.vue')
			},
			{
				path: '/campaign-management',
				name: 'campaignManagement',
				component: () => import('../pages/CampaignManage/CampaignView.vue')
			},
			{
				path: '/campaign-management/campaign-information/:id',
				name: 'ViewCampaignInformation',
				component: () => import('../pages/CampaignManage/CampaignDetail/CampaignDetail.vue')
			},
			{
				path: '/campaign-management/create-campaign',
				name: 'createCampaign',
				component: () => import('../pages/CampaignManage/CreateCampaign/CreateCampaign.vue'),
				children: [
					{
						path: '/campaign-management/create-campaign',
						name: 'ClientInformation',
						component: () => import('../pages/CampaignManage/CreateCampaign/ClientInformation/ClientInformation')
					},
					{
						path: '/campaign-management/create-campaign/campaign-information',
						name: 'CampaignInformation',
						component: () => import('../pages/CampaignManage/CreateCampaign/CampaignInformation/CampaignInformation.vue')
					},
					{
						path: '/campaign-management/create-campaign/media',
						name: 'Media',
						component: () => import('../pages/CampaignManage/CreateCampaign/Media/Media.vue')
					},
					{
						path: '/campaign-management/view-campaign/:id',
						name: 'ViewClientInformation',
						component: () => import('../pages/CampaignManage/CreateCampaign/ClientInformation/ClientInformation')
					},
					{
						path: '/campaign-management/view-campaign/media/:id',
						name: 'ViewMedia',
						component: () => import('../pages/CampaignManage/CreateCampaign/Media/Media.vue')
					},
					{
						path: '/campaign-management/edit-campaign/:id',
						name: 'EditClientInformation',
						component: () => import('../pages/CampaignManage/CreateCampaign/ClientInformation/ClientInformation')
					},
					{
						path: '/campaign-management/edit-campaign/campaign-information/:id',
						name: 'EditCampaignInformation',
						component: () => import('../pages/CampaignManage/CreateCampaign/CampaignInformation/CampaignInformation.vue')
					},
					{
						path: '/campaign-management/edit-campaign/media/:id',
						name: 'EditMedia',
						component: () => import('../pages/CampaignManage/CreateCampaign/Media/Media.vue')
					},
					{
						path: '/campaign-management/copy-campaign/:id',
						name: 'CopyClientInformation',
						component: () => import('../pages/CampaignManage/CreateCampaign/ClientInformation/ClientInformation')
					},
					{
						path: '/campaign-management/copy-campaign/campaign-information/:id',
						name: 'CopyCampaignInformation',
						component: () => import('../pages/CampaignManage/CreateCampaign/CampaignInformation/CampaignInformation.vue')
					},
					{
						path: '/campaign-management/copy-campaign/media/:id',
						name: 'CopyMedia',
						component: () => import('../pages/CampaignManage/CreateCampaign/Media/Media.vue')
					}
				]
			},
			{
				path: '/profile',
				name: 'ProfileView',
				component: () => import('../pages/ProfileView/ProfileView.vue'),
				children: [
					{
						path: '/profile',
						name: 'myProfile',
						component: () => import('../pages/ProfileView/MyProfile/MyProfile.vue')
					},
					{
						path: '/profile/manage-users',
						name: 'manageUsers',
						component: () => import('../pages/ProfileView/ManageUsers/ManageUsers.vue')
					},
					{
						path: '/profile/manage-users/add-user',
						name: 'addUser',
						component: () => import('../pages/ProfileView/ManageUsers/AddUser/AddUser.vue')
					},
					{
						path: '/profile/manage-users/edit-user/:id',
						name: 'editUser',
						component: () => import('../pages/ProfileView/ManageUsers/EditUser/EditUser.vue')
					},
					{
						path: '/profile/billings',
						name: 'billings',
						component: () => import('../pages/ProfileView/Billings/Billings.vue')
					},
					{
						path: '/profile/sparks',
						name: 'sparks',
						component: () => import('../pages/ProfileView/Sparks/SparkTotal.vue')
					},
					{
						path: '/profile/change-password',
						name: 'changePassword',
						component: () => import('../pages/ProfileView/ChangePassword/ChangePassword.vue')
					}
				]
			}
		]
	},
	{
		path: '/verification',
		name: 'verification',
		component: VerificationSent
	},
	{
		path: '/verify-success',
		name: 'verificationSuccess',
		component: VerificationSuccess
	},
	{
		path: '/verify-error',
		name: 'verificationError',
		component: VerificationError
	},
	{
		path: '/terms',
		name: 'terms',
		component: Terms
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		component: () => import('../pages/ForgotPassword/ForgotPassword.vue')
	},
	{
		path: '/new-password',
		name: 'NewPassword',
		component: () => import('../pages/ForgotPassword/NewPassword/NewPassword.vue')
	},
	{
		path: '/password-changed',
		name: 'PasswordChanged',
		component: () => import('../pages/ForgotPassword/PasswordChanged/PasswordChanged.vue')
	},
	{
		path: '/token-expired',
		name: 'TokenExpired',
		component: () => import('../pages/ForgotPassword/TokenExpired/TokenExpired.vue')
	}
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior: (to, from, savedPosition) => (savedPosition || { x: 0, y: 0 })
});

router.beforeEach((to, from, next) => {
	if (to.meta.requiredAuth && ((to.name !== 'Login' && !Storage.get(AUTH_KEY)) || !to.matched.length)) {
		next('/login');
		return;
	}

	next();
});

export default router;
