import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import CheckBox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Ripple from 'primevue/ripple';
import Menu from 'primevue/menu';
import Card from 'primevue/card';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmDialog from 'primevue/confirmdialog';
import Breadcrumb from 'primevue/breadcrumb';
import FileUpload from 'primevue/fileupload';
import ScrollPanel from 'primevue/scrollpanel';
import BlockUI from 'primevue/blockui';
import Loading from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup'; // optional for column grouping
import Row from 'primevue/row'; // optional for row
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dropdown from 'primevue/dropdown';
import Steps from 'primevue/steps';
import InputSwitch from 'primevue/inputswitch';
import TabMenu from 'primevue/tabmenu';
import Textarea from 'primevue/textarea';
import InputMask from 'primevue/inputmask';
import Password from 'primevue/password';
import MultiSelect from 'primevue/multiselect';
import Tag from 'primevue/tag';
import Calendar from 'primevue/calendar';
import Slider from 'primevue/slider';
import Tree from 'primevue/tree';
import Chart from 'primevue/chart';
import Tooltip from 'primevue/tooltip';
import Message from 'primevue/message';
import Dialog from 'primevue/dialog';
import ProgressBar from 'primevue/progressbar';
import ConfirmDialogCustom from './ConfirmDialog/ConfirmDialog.vue';
import ProgressSpinner from 'primevue/progressspinner';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';

const globalComponents = {
	install(app) {
		app.component('PrimeInputNumber', InputNumber);
		app.component('PrimeButton', Button);
		app.component('PrimeToast', Toast);
		app.component('PrimeCheckBox', CheckBox);
		app.component('PrimeInputText', InputText);
		app.component('PrimeMenu', Menu);
		app.component('PrimeCard', Card);
		app.component('PrimeConfirmPopup', ConfirmPopup);
		app.component('PrimeConfirmDialog', ConfirmDialog);
		app.component('PrimeBreadcrumb', Breadcrumb);
		app.component('PrimeFileUpload', FileUpload);
		app.component('PrimeScrollPanel', ScrollPanel);
		app.component('PrimeBlockUI', BlockUI);
		app.component('LoadingOverlay', Loading);
		app.component('PrimeDataTable', DataTable);
		app.component('PrimeColumn', Column);
		app.component('PrimeColumnGroup', ColumnGroup);
		app.component('PrimeRow', Row);
		app.component('PrimeTabView', TabView);
		app.component('PrimeTabPanel', TabPanel);
		app.component('PrimeDropdown', Dropdown);
		app.component('PrimeSteps', Steps);
		app.component('PrimeInputSwitch', InputSwitch);
		app.component('PrimeTabMenu', TabMenu);
		app.component('PrimeTextarea', Textarea);
		app.component('PrimeInputMask', InputMask);
		app.component('PrimePassword', Password);
		app.component('PrimeMultiSelect', MultiSelect);
		app.component('PrimeTag', Tag);
		app.component('PrimeCalendar', Calendar);
		app.component('PrimeSlider', Slider);
		app.component('PrimeTree', Tree);
		app.component('PrimeChart', Chart);
		app.component('PrimeMessage', Message);
		app.component('PrimeDialog', Dialog);
		app.component('PrimeProgressBar', ProgressBar);
		app.component('PrimeProgressSpinner', ProgressSpinner);
		app.component('PrimeConfirmDialogCustom', ConfirmDialogCustom);
		app.directive('tooltip', Tooltip);
		app.directive('ripple', Ripple);
	}
};

export default globalComponents;
