// formData - instance of FormData object
// data - object to post
function _getFormData(val, formData = new FormData(), namespace = '') {
	if (val === undefined || val === null) {
		return formData;
	}

	if (val instanceof Date) {
		formData.append(namespace, val.toISOString());
		return formData;
	}

	if (val instanceof File) {
		formData.append(namespace, val);
		return formData;
	}

	if (val instanceof Array) {
		for (let i = 0; i < val.length; i++) {
			const indexNamespace = `${namespace}[${i}]`;
			_getFormData(val[i], formData, indexNamespace);
		}
		return formData;
	}

	if (typeof val === 'object') {
		for (const [propertyName, propertyValue] of Object.entries(val)) {
			const propertyNameStr = namespace ? `${namespace}[${propertyName}]` : propertyName;
			_getFormData(propertyValue, formData, propertyNameStr);
		}
		return formData;
	}

	formData.append(namespace, val.toString());
	return formData;
}

/**
 * Parse object into FormData
 * @param {Object} params
 */
export const ObjectToFormData = (params = {}) => {
	return _getFormData(params);
};
