<template>
	<CDialog v-model:visible="visible" :modal="true" :header="header" :blockScroll="blockScroll" :position="position"
		class="p-confirm-dialog border-round-3xl" :breakpoints="breakpoints" :closeOnEscape="closeOnEscape" >
		<div class="flex flex-column justify-content-center align-items-center">
			<p class="mb-5 top-label-confirm" v-if="topLabel">{{topLabel}}</p>
			<em :class="iconClass" />
			<span v-if="messageUp" class="p-confirm-dialog-message font-bold text-2xl mb-2 text-center w-10">{{
					messageUp
			}}<label class="p-text-purple"> expire </label>at the end of tomorrow.</span>
			<img v-if="messageIcon" :src="icons(messageIcon)" alt="icon" class="w-10rem h-7rem mb-3">
			<span v-if="arrayItem && messageIcon !== 'IconDiscard'"
				class="p-confirm-dialog-message font-bold text-xl mb-2 text-left w-10">{{
						message
				}}</span>
			<span v-else-if="messageIcon === 'IconExpire'"
				class="p-confirm-dialog-message font-bold text-xl mb-2 text-center w-10">{{ message }}</span>
			<span v-else class="p-confirm-dialog-message font-bold text-xl mb-2 text-center w-full" :class="{'max-w-27rem ml-0' : isBilling}">{{ message }}</span>

			<ul v-if="arrayItem" :class="checkText ? 'w-10' : 'w-full'">
				<template v-for="(item, idx) in arrayItem" :key="idx">
					<li class="p-1 text-lg">{{ item }}</li>
				</template>
			</ul>
			<span class="break-line-list font-bold text-xl mb-2 text-center w-10">{{ messageChild }}</span>
		</div>
		<template #footer>
			<PrimeButton :label="rejectLabel" :icon="rejectIcon" :class="rejectClass" @click="reject()"
				:autofocus="autoFocusReject" />
			<PrimeButton :label="acceptLabel" :icon="acceptIcon" :class="acceptClass" @click="accept()"
				:autofocus="autoFocusAccept" />
		</template>
	</CDialog>
</template>

<script>
import ConfirmationEventBus from 'primevue/confirmationeventbus';
import Dialog from 'primevue/dialog';
import IconDiscard from '@/assets/images/icon-discard.svg';
import IconUpVideo from '../../assets/images/upload-video.svg';
import IconNotClient from '../../assets/images/not-yet-client.svg';
import IconImgQuality from '../../assets/images/image-quality.svg';
import IconExpire from '../../assets/images/expire-campaign.svg';
import IconPaymentFail from '../../assets/images/payment-fail.svg';
import IconPaymentSuccess from '../../assets/images/payment-success.svg';
import IconRefund from '../../assets/images/refund-popup.svg';

export default {
	name: 'ConfirmDialog',
	props: {
		group: String,
		arrayItem: Array,
		checkText: Boolean,
		breakpoints: {
			type: Object,
			default: null
		},
		isBilling: Boolean
	},
	confirmListener: null,
	closeListener: null,
	data() {
		return {
			visible: false,
			confirmation: null
		};
	},
	mounted() {
		this.confirmListener = (options) => {
			if (!options) {
				return;
			}
			if (options.group === this.group) {
				this.confirmation = options;
				this.visible = true;
			}
		};
		this.closeListener = () => {
			this.visible = false;
			this.confirmation = null;
		};
		ConfirmationEventBus.on('confirm', this.confirmListener);
		ConfirmationEventBus.on('close', this.closeListener);
	},
	beforeUnmount() {
		ConfirmationEventBus.off('confirm', this.confirmListener);
		ConfirmationEventBus.off('close', this.closeListener);
	},
	methods: {
		accept() {
			if (this.confirmation.accept) {
				this.confirmation.accept();
			}
			this.visible = false;
		},
		reject() {
			if (this.confirmation.reject) {
				this.confirmation.reject();
			}
			this.visible = false;
		},
		icons(icon) {
			switch (icon) {
				case 'IconDiscard':
					return IconDiscard;
				case 'IconUpVideo':
					return IconUpVideo;
				case 'IconNotClient':
					return IconNotClient;
				case 'IconImgQuality':
					return IconImgQuality;
				case 'IconExpire':
					return IconExpire;
				case 'IconPaymentFail':
					return IconPaymentFail;
				case 'IconPaymentSuccess':
					return IconPaymentSuccess;
				case 'IconRefund':
					return IconRefund;
				default:
					break;
			}
		}

	},
	computed: {
		header() {
			return this.confirmation ? this.confirmation.header : null;
		},
		message() {
			return this.confirmation ? this.confirmation.message : null;
		},

		topLabel() {
			return this.confirmation ? this.confirmation.topLabel : null;
		},
		messageIcon() {
			return this.confirmation ? this.confirmation.messageIcon : null;
		},
		messageChild() {
			return this.confirmation ? this.confirmation.messageChild : null;
		},
		messageUp() {
			return this.confirmation ? this.confirmation.messageUp : null;
		},
		blockScroll() {
			return this.confirmation ? this.confirmation.blockScroll : true;
		},
		position() {
			return this.confirmation ? this.confirmation.position : null;
		},
		iconClass() {
			return ['p-confirm-dialog-icon', this.confirmation ? this.confirmation.icon : null];
		},
		acceptLabel() {
			return this.confirmation ? (this.confirmation.acceptLabel || this.$primevue.config.locale.accept) : null;
		},
		rejectLabel() {
			return this.confirmation ? (this.confirmation.rejectLabel || this.$primevue.config.locale.reject) : null;
		},
		acceptIcon() {
			return this.confirmation ? this.confirmation.acceptIcon : null;
		},
		rejectIcon() {
			return this.confirmation ? this.confirmation.rejectIcon : null;
		},
		acceptClass() {
			return ['p-confirm-dialog-accept', this.confirmation ? this.confirmation.acceptClass : null];
		},
		rejectClass() {
			return ['p-confirm-dialog-reject', this.confirmation ? (this.confirmation.rejectClass || 'p-button-text') : null];
		},
		autoFocusAccept() {
			return (this.confirmation.defaultFocus === undefined || this.confirmation.defaultFocus === 'accept');
		},
		autoFocusReject() {
			return this.confirmation.defaultFocus === 'reject';
		},
		closeOnEscape() {
			return this.confirmation ? this.confirmation.closeOnEscape : true;
		}
	},
	components: {
		CDialog: Dialog
	}
};
</script>
