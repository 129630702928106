import { RESPONSE_CODE, CONTACT_ADMINISTRATION, NOT_PERMISSION } from '@/common/const/modules/index';

export function registerComponent(vm, name, component) {
	if (name in vm.$options.components) return; // don't overwrite components, strange behavior ensues

	vm.$options.components[name] = component;
}

export function pushOnBeforeUnload(msg) {
	const oldOnBeforeUnload = window.onbeforeunload;

	window.onbeforeunload = (e) => {
		e.returnValue = e;

		return msg;
	};

	let calledClear = false;
	return () => {
		if (calledClear) return;

		window.onbeforeunload = oldOnBeforeUnload;
		calledClear = true;
	};
}

export const queryParamsParser = (params = {}) => {
	let sortOrder = 0;
	if (params?.sortOrder === 1) {
		sortOrder = 1;
	}
	if (params?.sortOrder === -1) {
		sortOrder = 2;
	}
	const queryParams = {
		PageIndex: params?.page,
		PageSize: params?.rows,
		SearchText: params?.filters ? params?.filters : null,
		SearchFields: null,
		StartDate: params?.startDate,
		EndDate: params?.endDate,
		OrderBy: params?.sortField,
		Direction: sortOrder
	};

	for (const key in queryParams) {
		if (!queryParams[key]) {
			delete queryParams[key];
		}
	}

	return queryParams;
};

export const preventWord = (event) => {
	const digitPattern = /^\d$/;
	const acceptKeys = [8, 9, 13, 46, 37, 38, 39, 40];
	if (acceptKeys.indexOf(event.keyCode) >= 0) {
		return;
	}
	if (!digitPattern.test(event.key)) {
		event.preventDefault();
	}
};

export const generateReturnUrl = (path) => {
	return `${window.location.origin}${path}`;
};

export const numberWithComma = (str) => {
	if (!str) {
		return '';
	}
	return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const handleErrorApi = (reps) => {
	let message = '';
	const respErr = reps?.data;
	switch (reps?.status) {
		case RESPONSE_CODE.NOT_PERMISSION:
			message = NOT_PERMISSION;
			break;
		case RESPONSE_CODE.INTERNAL_SERVER_ERROR:
			message = CONTACT_ADMINISTRATION;
			break;
		case RESPONSE_CODE.BAD_REQUEST:
			if (respErr?.errorDetails?.amount) {
				message = respErr.errorDetails.amount.toString();
			} else if (respErr?.errorMessage) {
				message = respErr?.errorMessage;
			} else {
				message = reps?.statusText;
			}
			break;
		default:
			message = reps?.statusText || CONTACT_ADMINISTRATION;
			break;
	}
	return message;
};
