import { vue3Debounce } from 'vue-debounce';
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { globalMixins } from './mixins';
import init from './init';
import components from './components/';
import directives from './directives';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import './assets/scss/styles.scss';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRightLong, faArrowLeftLong, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import DialogService from 'primevue/dialogservice';

library.add(faArrowRightLong, faArrowLeftLong, faPenToSquare);

const appInstance = createApp(App);
appInstance.mixin(globalMixins);
appInstance.use(init);
appInstance.use(PrimeVue, { inputStyle: 'filled', ripple: true });
appInstance.use(ToastService);
appInstance.use(ConfirmationService);
appInstance.use(DialogService);
appInstance.use(components);
appInstance.use(directives);
appInstance.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAP_API_KEY
	}
});
appInstance.use(router);
appInstance.component('font-awesome-icon', FontAwesomeIcon);
appInstance.directive('debounce', vue3Debounce({ lock: true }));
appInstance.mount('#app');
