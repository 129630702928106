<template>
	<PrimeConfirmPopup group="avatar" class="mt-0">
		<template #message>
			<div class="w-150">
				<router-link class="flex justify-content-center" to="/profile" @click="confirm.close()">
					<div class="flex p-1 flex-column ">
						<div class="flex pt-3 pb-10px  text-900">
							<label class=" pi pi-user mr-2"></label>
							<span class="line-height-1">My Profile</span>
						</div>
					</div>
				</router-link>
				<router-link class="flex justify-content-center" to="/profile/sparks" @click="confirm.close()">
					<div class="flex p-1 flex-column">
						<div class="flex pt-3 pb-10px  mr-4 text-900 ">
							<label class=" pi pi-database mr-2"></label>
							<span class="line-height-1">Sparks</span>
						</div>
					</div>
				</router-link>
				<router-link class="flex justify-content-center" to="/login" @click="signOut">
					<div class="flex p-1 flex-column justify-content-start">
						<div class=" border-200 flex border-top-1 w-full "></div>
						<div class="flex pt-3 pb-10px  mr-4 text-orange-500">
							<span class="pi pi-sign-out mr-2"></span>
							<span class="line-height-1">Logout</span>
						</div>
					</div>
				</router-link>
			</div>
		</template>
	</PrimeConfirmPopup>
	<a class="flex p-3 lg:px-3 lg:py-2 align-items-center  font-medium border-round cursor-pointer transition-duration-150 transition-colors p-ripple"
		@click="showTemplate($event)">
		<img v-if="profile?.logoUrl" v-modifyImg="profile?.logoUrl" :src="profile?.logoUrl" alt=""
			class="mr-3 lg:mr-2 avatar-img-small" />
		<template v-else>
			<img alt="" src="../../assets/images/no-image.jpg" class="mr-3 lg:mr-2 avatar-img-small" />
		</template>
		<div class="block">
			<span class="text-0 text-base font-medium text-sm">{{ profile?.name || '---' }}</span>
		</div>
		<span class="p-ink"></span>
	</a>
</template>
<script>
import { useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import { signout } from '@/services/auth.service';
import { useAuth } from '@/composables/useAuth';
import { useProfile } from '@/composables/userProfile';
import { REFRESH_TOKEN_KEY, RESPONSE_CODE } from '@/common/const/modules/index';
import Storage from '@/common/helpers/storage/localStorage';

export default {
	setup() {
		const confirm = useConfirm();
		const router = useRouter();
		const { profile } = useProfile();
		const { logout, error } = useAuth();

		const signOut = async () => {
			const { status, statusText } = await signout(Storage.get(REFRESH_TOKEN_KEY));
			if ([RESPONSE_CODE.SUCCESS, RESPONSE_CODE.NO_CONTENT].includes(status)) {
				logout();
				router.push('/login');
			} else {
				// can not revoke token
				error.value = statusText;
				logout();
				router.push('/login');
			}
		};

		const showTemplate = (event) => {
			confirm.require({
				target: event.currentTarget,
				group: 'avatar',
				message: `${profile.value?.name || '---'}`,
				acceptClass: 'hidden',
				rejectClass: 'hidden',
				acceptLabel: 'Sign out',
				rejectLabel: 'Profile',
				accept: signOut,
				reject: () => {
					router.push('/profile');
				}
			});
		};

		return { profile, showTemplate, signOut, confirm };
	},
	directives: {
		modifyImg: (el, binding) => {
			if (binding.value instanceof File) {
				const reader = new FileReader();
				reader.readAsDataURL(binding.value);
				reader.onloadend = function () {
					el.src = reader.result;
				};
			} else {
				el.src = binding.value.replace('data:image/png,base64', 'data:image/png;base64');
			}
		}
	}
};
</script>
<style lang="scss">
.avatar {
	&-card {
		width: 20em;
		box-shadow: none !important;

		.p-card-content {
			padding: 0;
		}
	}

	&-header {
		background-color: var(--primary-color);
		color: white;

	}
}

.avatar-img-small {
	width: 46px;
	height: 46px;
	border: 2px solid #F5953B;
	border-radius: 50px;
}

.p-confirm-popup:before {
	margin-left: 16px !important;
}

.p-confirm-popup:after {
	margin-left: 16px !important;
}

.w-150 {
	min-width: 150px;

	& a:hover {
		background-color: #EFEDF0;
	}
}

.h-136 {
	min-height: 136px;
}

.p-confirm-popup .p-confirm-popup-footer {
	display: none;
}

.pb-10px {
	padding-bottom: 10px;
}
</style>
