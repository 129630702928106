import { AUTH_KEY, ROLES } from '@/common/const/modules';
import { reactive, toRefs } from 'vue';
import Storage from '@/common/helpers/storage/localStorage';
import jwtDecode from 'jwt-decode';

const defaultAccessToken = Storage.get(AUTH_KEY) ? jwtDecode(Storage.get(AUTH_KEY)) : null;

const roleState = reactive({
	role: defaultAccessToken?.role || ROLES.USER,
	isAgency: defaultAccessToken?.role === ROLES.ADMINISTRATOR,
	isUser: defaultAccessToken?.role === ROLES.USER,
	isAdmin: defaultAccessToken?.role === ROLES.SUPER_ADMINISTRATOR
});

const permissionsState = reactive({
	permissions: Storage.get(AUTH_KEY) ? jwtDecode(Storage.get(AUTH_KEY))?.permissions : null,
	isAgencyUserRead: defaultAccessToken?.permissions?.includes('AgencyUserRead'),
	isAgencyUserUpdate: defaultAccessToken?.permissions?.includes('AgencyUserUpdate'),
	isAgencyUserCreate: defaultAccessToken?.permissions?.includes('AgencyUserCreate'),
	isBillingRead: defaultAccessToken?.permissions?.includes('BillingRead'),
	isCampaignRead: defaultAccessToken?.permissions?.includes('CampaignRead'),
	isCampaignUpdate: defaultAccessToken?.permissions?.includes('CampaignUpdate'),
	isCampaignCreate: defaultAccessToken?.permissions?.includes('CampaignCreate'),
	isClientRead: defaultAccessToken?.permissions?.includes('ClientRead'),
	isClientUpdate: defaultAccessToken?.permissions?.includes('ClientUpdate'),
	isClientCreate: defaultAccessToken?.permissions?.includes('ClientCreate')
});

export const useRole = () => {
	const fetchRole = () => {
		roleState.role = Storage.get(AUTH_KEY) ? jwtDecode(Storage.get(AUTH_KEY))?.role : null;
		roleState.isAgency = roleState.role === ROLES.ADMINISTRATOR;
		roleState.isUser = roleState.role === ROLES.USER;
		roleState.isAdmin = roleState.role === ROLES.SUPER_ADMINISTRATOR;
	};

	return {
		fetchRole,
		...toRefs(roleState)
	};
};

export const usePermissions = () => {
	const fetchPermissions = () => {
		permissionsState.permissions = Storage.get(AUTH_KEY) ? jwtDecode(Storage.get(AUTH_KEY))?.permissions : null;
		permissionsState.isAgencyUserRead = permissionsState.permissions?.includes('AgencyUserRead');
		permissionsState.isAgencyUserUpdate = permissionsState.permissions?.includes('AgencyUserUpdate');
		permissionsState.isAgencyUserCreate = permissionsState.permissions?.includes('AgencyUserCreate');
		permissionsState.isBillingRead = permissionsState.permissions?.includes('BillingRead');
		permissionsState.isCampaignRead = permissionsState.permissions?.includes('CampaignRead');
		permissionsState.isCampaignUpdate = permissionsState.permissions?.includes('CampaignUpdate');
		permissionsState.isCampaignCreate = permissionsState.permissions?.includes('CampaignCreate');
		permissionsState.isClientRead = permissionsState.permissions?.includes('ClientRead');
		permissionsState.isClientUpdate = permissionsState.permissions?.includes('ClientUpdate');
		permissionsState.isClientCreate = permissionsState.permissions?.includes('ClientCreate');
	};
	return {
		fetchPermissions,
		...toRefs(permissionsState)
	};
};
