import { reactive, toRefs } from 'vue';
import { getNotifications, getAmountNotifications } from '@/services/notification.service';
import { queryParamsParser } from '@/common/helpers/utils';
const itemNotification = reactive({
	listNotification: [],
	amountNotification: 0,
	error: undefined
});
export const useNotification = () => {
	const setNotification = async (payload) => {
		try {
			const response = await getNotifications(queryParamsParser(payload));
			itemNotification.listNotification = response.data;
		} catch (error) {
			itemNotification.error = error;
		}
	};
	const setAmountNotification = async () => {
		try {
			const response = await getAmountNotifications();
			itemNotification.amountNotification = response.data;
		} catch (error) {
			itemNotification.error = error;
		}
	};
	return {
		setNotification,
		setAmountNotification,
		...toRefs(itemNotification)
	};
};
