<template>
	<div class="notification-all">
		<PrimeConfirmPopup group="list-notification" :closable="false" class="notification mt-3" :blockScroll="false">
			<template #message>
				<div class="relative">
					<div class="main-list pb-4 overflow-y-scroll" ref="getHeightScroll" @scroll="handleScroll"
						id="scrollBar">
						<div
							class="header-main-list absolute flex justify-content-between align-items-end border-gray-200 border-bottom-2">
							<span class="p-input-icon-left noti">
								<i class="pi pi-search" />
								<PrimeInputText class="btn-rounded active" v-model.trim="filters"
									placeholder="Search by Name" @keydown.enter="onFilter"
									v-debounce:1s="onFilter" :maxlength="maxLength" />
							</span>
							<span class="font-bold cursor-pointer text-16 text-read-all pr-4" ref="target"
								:class="{ 'p-disabled d-none': dataList.length <= 0 }" @click="readAll">Read all</span>
						</div>
						<div v-if="dataList.length > 0" class="flex flex-column mt-8 list-scroll">
							<template v-for="(item, id) in dataList" :key="id">
								<div class="flex justify-content-center align-items-center w-full my-3">
									<img :src="require('@/assets/images/imageNotification/' + setImgType(item.actionType, item.createdBy))"
										alt="notifications" />
									<div class="w-7 mx-5 cursor-pointer"
										@click="navigateItem(item.id, item.actionType, item.isRead, item.dataObject)">
										<span class="text-16 text-gray-bold semi-bold"> <span
												class="text-16 text-gray-bold extra-bold line-break-anywhere">{{
													item.actionType === ACTION_TYPE.PAYMENT ? item.dataObject?.CreatedByName :
													item.dataObject?.CampaignName }}</span> <span
												:class="{ 'd-none ': item.dataObject?.ClientName === undefined }"> of
											</span>
											<span class="text-16 text-gray-bold extra-bold "> {{ item.dataObject?.ClientName
												|| '' }}</span> {{
		item.textUpdate
	}}<strong v-if="item.actionType !== ACTION_TYPE.TRACK_DUE_DATE"
												class="extra-bold">{{ numberWithComma(item.dataObject?.NumberOfSpark) }}
												<span>sparks</span>
											</strong> </span>
										<span v-if="item.actionType === ACTION_TYPE.TRACK_DUE_DATE"
											class="text-gray-bold extra-bold text-16 "> {{ item.dataObject?.CampaignEndDate
											}}</span>
										<span v-else class="text-500 regular"> on {{ item.createdAt }}</span>
									</div>
									<img class="cursor-pointer"
										:src="require('@/assets/images/imageNotification/' + (!item.isRead ? 'read-noti.svg' : 'unread-noti.svg'))"
										alt="read" @click="setStatusRead(item.id, item.isRead)" />
								</div>
							</template>
							<div v-if="listNotification?.items?.length === listNotification?.totalCount"
								class="flex align-items-center flex-column py-4">
								<img class="" src="@/assets/images/imageNotification/end-list.svg" alt="flag">
								<span class="regular text-500 w-5 text-center">That's all your notifications from the last
									90 days.</span>
							</div>
						</div>
						<div v-else class="flex h-full flex-column justify-content-center align-items-center">
							<img src="@/assets/images/imageNotification/no-data.png" alt="no-data">
						</div>
					</div>
				</div>
			</template>
		</PrimeConfirmPopup>
		<div class="relative flex align-items-center justify-content-center w-4rem cursor-pointer">
			<div class="bell-customs border-circle mr-3 bg-white flex align-items-center justify-content-center "  @click="showNotificationList($event)">
				<img src="@/assets/bell.svg" alt="bell">
			</div>
			<span v-if="amountNotification > 0"
				class="amount-bell-span absolute text-white top-0 border-round-xl text-center text-xs ">{{
					amountNotification > 99 ? '99+' : amountNotification }}</span>
		</div>
	</div>
</template>
<script>
import { useConfirm } from 'primevue/useconfirm';
import { onMounted, ref } from 'vue';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { DATE_TIME_FORMAT, CAMPAIGN_LIST_CHECK_DATE_FORMAT, NUMBER_PAGE_SIZE_NOTIFICATION, ACTION_TYPE, RESPONSE_CODE, MAX_LENGTH_INPUT_TEXT } from '@/common/const/modules/index';
import { handleErrorApi, numberWithComma } from '@/common/helpers/utils';
import { useNotification } from '@/composables/useNotification';
import { updateRead, updateReadAll } from '@/services/notification.service';
import { useToast } from 'primevue/usetoast';
export default {
	setup() {
		const confirm = useConfirm();
		const dataList = ref([]);
		const getHeightScroll = ref(null);
		const router = useRouter();
		const toast = useToast();
		const filters = ref('');
		const maxLength = ref(MAX_LENGTH_INPUT_TEXT);
		const isShow = ref(false);
		const { listNotification, setNotification, amountNotification, setAmountNotification } = useNotification();
		let timeoutIdRead;
		const setStatusRead = (idx, isReads, index) => {
			clearTimeout(timeoutIdRead);
			timeoutIdRead = setTimeout(async () => {
				try {
					const reps = await updateRead(idx, !isReads);
					if (reps.status === RESPONSE_CODE.NO_CONTENT) {
						dataList.value.forEach((itemStatus) => {
							if (itemStatus.id === idx) {
								itemStatus.isRead = !isReads;
							}
						});
						await setAmountNotification();
					}
				} catch (error) {
					toast.add({ severity: 'error', summary: 'Error', detail: handleErrorApi(error.reps), life: 3000 });
				}
			}, 200);
		};
		const readAll = async () => {
			try {
				const reps = await updateReadAll();
				if (reps.status === RESPONSE_CODE.NO_CONTENT) {
					dataList.value.forEach((itemStatusAll) => {
						itemStatusAll.isRead = true;
					});
					await setAmountNotification();
				}
			} catch (error) {
				toast.add({ severity: 'error', summary: 'Error', detail: handleErrorApi(error.reps), life: 3000 });
			}
		};
		onMounted(async () => {
			await setAmountNotification();
		});
		const updateData = async (params) => {
			await setNotification({
				rows: params.pageSize,
				filters: params.searchText,
				page: 0
			});
			dataList.value = listNotification.value?.items?.filter((itemFilter) => itemFilter?.dataObject?.NumberOfSpark !== 0).map((itemText) => {
				return {
					...itemText,
					textUpdate: ''
				};
			}) || [];
			dataList.value?.forEach((itemNoti) => {
				itemNoti.createdAt = moment.utc(itemNoti.createdAt).local().format(DATE_TIME_FORMAT);
				itemNoti.dataObject.CampaignEndDate = moment.utc(itemNoti.dataObject?.CampaignEndDate).local().format(CAMPAIGN_LIST_CHECK_DATE_FORMAT);
				switch (itemNoti.actionType) {
					case ACTION_TYPE.COMPLETE_CAMPAIGN:
						if (itemNoti?.dataObject?.CreatedByName) {
							itemNoti.textUpdate = ' has been completed and refunded of ';
						} else {
							itemNoti.textUpdate = ' has reached the target with the spare of ';
						}
						break;
					case ACTION_TYPE.CREATE_CAMPAIGN:
						itemNoti.textUpdate = ' has been created with ';

						break;
					case ACTION_TYPE.PAYMENT:
						itemNoti.textUpdate = ' performed a transaction of adding ';

						break;
					default:
						itemNoti.textUpdate = ' will expire at the end of  ';
						break;
				}
			});
		};
		let initValue = NUMBER_PAGE_SIZE_NOTIFICATION;
		const showNotificationList = async (event) => {
			initValue = NUMBER_PAGE_SIZE_NOTIFICATION;
			updateData({ pageSize: initValue, searchText: filters.value });
			confirm.require({
				target: event.currentTarget,
				group: 'list-notification',
				acceptClass: ' absolute custom-button-notification',
				rejectClass: 'hidden',
				accept: () => {
					filters.value = '';
				},
				onHide: () => {
					filters.value = '';
				}
			});
		};
		const onFilter = () => {
			const element = document.getElementById('scrollBar');
			updateData({ pageSize: initValue, searchText: filters.value, page: 0 });
			element.scrollTop = 0;
		};
		const loading = ref(false);
		const handleScroll = async (event) => {
			const numberScroll = getHeightScroll.value;
			if (numberScroll.scrollTop + numberScroll.clientHeight === numberScroll.scrollHeight) {
				loading.value = true;
				if (listNotification.value?.items?.length < listNotification.value?.totalCount) {
					initValue += NUMBER_PAGE_SIZE_NOTIFICATION;
					updateData({ pageSize: initValue, searchText: filters.value });
					loading.value = false;
				}
			}
		};
		const navigateItem = async (idItem, type, isRead, data) => {
			const reps = await updateRead(idItem, true);
			if (reps.status === RESPONSE_CODE.NO_CONTENT) {
				setAmountNotification();
			}
			confirm.close();
			if (type === ACTION_TYPE.COMPLETE_CAMPAIGN || type === ACTION_TYPE.PAYMENT) {
				router.push('/profile/sparks');
			} else {
				router.push(`/campaign-management/campaign-information/${data?.CampaignId}`);
			}
			filters.value = '';
		};
		const setImgType = (acType, createdBy) => {
			let nameImage = '';
			switch (acType) {
				case ACTION_TYPE.COMPLETE_CAMPAIGN:
					if (createdBy) {
						nameImage = 'sparkRefund.svg';
					} else {
						nameImage = 'auto-complete.svg';
					}
					break;
				case ACTION_TYPE.CREATE_CAMPAIGN:
					nameImage = 'create-new.svg';
					break;
				case ACTION_TYPE.PAYMENT:
					nameImage = 'transactionNew.svg';
					break;
				default:
					nameImage = 'campaign-expire.svg';
					break;
			}
			return nameImage;
		};
		return {
			setImgType,
			readAll,
			navigateItem,
			showNotificationList,
			confirm,
			dataList,
			numberWithComma,
			setStatusRead,
			handleScroll,
			getHeightScroll,
			loading,
			ACTION_TYPE,
			amountNotification,
			listNotification,
			filters,
			maxLength,
			isShow,
			onFilter
		};
	}
};
</script>
<style lang="scss">
.notification {
	margin-left: -1rem;
	top: 41.5px !important;
	border-radius: 10px !important;
}

.notification::before {
	content: none !important;
}

.notification::after {
	content: none !important;
}

.bell-customs {
	width: 32px;
	height: 32px;
}

.amount-bell-span {
	width: 30px;
	height: 20px;
	background: linear-gradient(180deg, #FA5050 0%, #E60000 100%);
	border: 3px solid #1F1F1F;
	right: -4px;
}

.main-list {
	width: 530px;
	height: 620px;
}

#scrollBar::-webkit-scrollbar {
	border-radius: 0 12px 12px 0;
	background-color: #F5F5F5;
	width: 12px;
}

#scrollBar::-webkit-scrollbar-thumb {
	background-color: #C1C1C1;
	border-radius: 12px;
	height: 15px;
}

.list-scroll {
	height: 530px;
}

.header-main-list {
	right: 3%;
	left: 0%;
	border-radius: 10px 10px 0 0;
	background-color: white;
	padding: 0 0 10px 30px;

	.p-input-icon-left {
		background: white !important;
		top: 10px;
		.p-search{
			position: relative;
			top: 50%;
		}
		.p-inputtext {
			background: white !important;
			border-radius: 20px;
			font-size: 16px !important;
			border: 1px solid #00000061;
			margin-bottom: 10px;
			padding-left: 45px;
			width: 376px;

			&:hover {
				border: 1px solid #00000061 !important;
			}
		}

		.p-inputtext:enabled:focus {
			border: 1px solid #00000061 !important;
		}

		.pi-search {
			top: 40%;
			font-size: 23px;
			transform: translateY(-50%);
		}
	}
}

.notification .p-confirm-popup-footer {
	display: block !important;
	padding: 0 !important;
}

.notification .p-confirm-popup-footer .custom-button-notification {
	top: -3.6rem;
	right: -2rem;
	opacity: 0 !important;
}

.text-16 {
	font-size: 16px;
}

.text-read-all {
	color: #1F1F1F;
}
</style>
