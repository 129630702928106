<template>
	<div class="px-4 py-8 flex flex-column align-items-center justify-content-start">
		<div
			class="surface-card py-8 shadow-2 border-round w-full lg:w-6 flex flex-column align-items-center justify-content-center">
			<div class="text-center font-bold">
				<div class="text-3xl mb-5">Verify Your Email Address</div>
				<div class="text-xl mb-3">To continue using LightX, please verify your email address</div>
				<div class="text-xl mb-4">{{ email }}</div>
			</div>
			<PrimeButton class="p-ripple btn-primary btn-rounded btn-theme-error" type="submit" label="Close"
				@click="onClickClose()">
				<span>Close</span>
			</PrimeButton>
		</div>
	</div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';

export default {
	name: 'VerificationSent',
	setup() {
		const route = useRoute();
		const router = useRouter();
		const email = route.query.email ? decodeURIComponent(route.query.email) : undefined;
		const onClickClose = () => {
			router.push('/login');
		};
		return {
			email,
			onClickClose
		};
	}
};
</script>

<style lang='scss' scoped>
@import './style.scss';
</style>
