import axios from 'axios';
import VueAxios from 'vue-axios';
import constants from './common/const';
import Notify from './common/helpers/notify';
import ErrorHandler from './common/helpers/api/errorHandler';
import { REQUEST_TYPE } from './common/configs/api';

const plugin = {
	install (Vue) {
		Vue.use(VueAxios, axios);
		Vue.provide('$const', constants);
		Vue.provide('$notify', Notify);
		Vue.provide('$errorHandler', ErrorHandler);

		axios.interceptors.request.use((request) => {
			const method = request.method.toUpperCase();
			if (method === REQUEST_TYPE.PUT || method === REQUEST_TYPE.DEL || method === REQUEST_TYPE.PATCH) {
				request.headers['X-HTTP-Method-Override'] = method;
				request.method = 'post';
			}
			return request;
		});
	}
};

export default plugin;
