const getParentHasIdElementRecursion = (node) => {
	if (node && !node.id) {
		return getParentHasIdElementRecursion(node.parentElement);
	} else {
		return node;
	}
};

const disableElement = (elementNode) => {
	elementNode.disabled = true;
	if (elementNode.classList.contains('p-component')) {
		elementNode.classList.add('p-disabled');
	}
};
const enableElement = (elementNode) => {
	elementNode.disabled = false;
	if (elementNode.classList.contains('p-component')) {
		elementNode.classList.remove('p-disabled');
	}
};

const disabledRecursionElement = (ele) => {
	const elementReal = getParentHasIdElementRecursion(ele);
	if (elementReal) {
		disableElement(elementReal);
	}
};
const enabledRecursionElement = (ele) => {
	const elementReal = getParentHasIdElementRecursion(ele);
	if (elementReal) {
		enableElement(elementReal);
	}
};

// Here is directive handle for disable all input available in form but currently it not cover for all component of prime
// Need to update more case in future.
const disableAllFormInput = {
	mounted: (el, binding) => {
		if (binding.value?.apply) {
			el.classList.add('disabled-all-form-input');
			for (const formElement of el.elements) {
				if (formElement) {
					formElement.disabled = true;
					if (!formElement.id) {
						disabledRecursionElement(formElement);
					} else if (formElement.type === 'checkbox') {
						const elementReal = formElement.parentElement.parentElement;
						if (elementReal) {
							disableElement(elementReal);
						}
					}
				}
			}
		} else {
			el.classList.remove('disabled-all-form-input');
			for (const formElement of el.elements) {
				if (formElement) {
					formElement.disabled = false;
					if (!formElement.id) {
						enabledRecursionElement(formElement);
					} else if (formElement.type === 'checkbox') {
						const elementReal = formElement.parentElement.parentElement;
						if (elementReal) {
							enableElement(elementReal);
						}
					}
				}
			}
		}
	}
};

export default disableAllFormInput;
